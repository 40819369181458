import React from "react"
import Layout from "../../components/layout/Layout"
import Head from "../../components/layout/Head"
import styled from "styled-components"

const Title = styled.h2`
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 3rem;
  margin-bottom: 1rem;
`
const Blurb = styled.h1`
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  padding: 1rem 0 0.8rem 0;
  border-top: 5px solid hsla(0, 0%, 0%, 0.8);
  border-bottom: 5px solid hsla(0, 0%, 0%, 0.8);
`

const ProjectPage = () => (
  <>
    <Head title="Liam Hawks | Quick Stock Project" />
    <Layout>
      <Title>Project Title</Title>
      <Blurb>Project Blurb</Blurb>
      <section id="description">
        <p>Project description</p>
      </section>
    </Layout>
  </>
)

export default ProjectPage
